import {useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InAppWebPinPano from '../../../assets/InAppWebPinPano.png';
import {useVuplex} from '../../../hooks/useVuplex';
import {clustersInAppClear, clustersInAppRequest} from '../../../reducers/pinsReducer';
import {sendNavTo, sendPanoLoad, sendPlacesSearch} from '../../../vuplex';
import InAppMapPin from '../UnityReactComponents/InAppMapPin';

const refresh = _.debounce((dispatch, bounds, worldId, mapsCore) => {

  const gBounds = new mapsCore.LatLngBounds();
  gBounds.extend(new mapsCore.LatLng(bounds.corner1Lat, bounds.corner1Lon));
  gBounds.extend(new mapsCore.LatLng(bounds.corner2Lat, bounds.corner2Lon));
  gBounds.extend(new mapsCore.LatLng(bounds.corner3Lat, bounds.corner3Lon));
  gBounds.extend(new mapsCore.LatLng(bounds.corner4Lat, bounds.corner4Lon));
  const topRight = gBounds.getNorthEast();
  const botLeft = gBounds.getSouthWest();

  console.log('fetch clusters zoom, sw, ne:', bounds.zoom, botLeft.toJSON(), topRight.toJSON())

  dispatch(clustersInAppRequest({
    bounds: {
      topRight: {Latitude: topRight.lat(), Longitude: topRight.lng()},
      bottomLeft: {Latitude: botLeft.lat(), Longitude: botLeft.lng()},
    },
    worldId,
    zoom: bounds.zoom + 2,
  }));
}, 1000);

const InAppPinsAndClusters = ({}) => {
  const dispatch = useDispatch();

  const clusters = useSelector(s => s.pins.clustersInApp.response) || [];
  const isLoading = useSelector(s => s.pins.clustersInApp.isLoading);
  const worldId = useSelector(s => s.inApp.world._id) || {};

  const panoId = useSelector(s => s.inApp.panoId);
  const panoLocation = useSelector(s => s.inApp.panoLocation);
  const isPanoLoaded = !!(panoId && panoLocation);

  //TODO maybe hide a pin if pano is loaded. PanoMarker is sometimes in a different location than the pin though so maybe we leave it.
  // const panoIdLoaded = useSelector(s => s.inApp.panoId);
  // const panoLocationLoaded = useSelector(s => s.inApp.panoLocation);

  const map = useMap();
  const mapsCore = useMapsLibrary('core');

  // const mapLocation = useSelector(s => s.inApp.mapLocation);

  const [isTabletop, setIsTabletop] = useState(true);

  useVuplex({
    mapBounds: (bounds) => {
      if (isPanoLoaded) return;
      // console.log(bounds)
      refresh(dispatch, bounds, worldId, mapsCore);
      setIsTabletop(bounds.isTabletop);
    },
  }, [isPanoLoaded, mapsCore, worldId]);

  useEffect(() => {
    _.delay(() => {
      refresh.flush();
    }, 500);
  }, [isTabletop]);

  useEffect(() => {
    if (!isLoading && isPanoLoaded) {
      refresh.cancel();
      dispatch(clustersInAppClear());
    }
  }, [isLoading, isPanoLoaded])

  // only show the first instance of a pinned pano
  const sortedUniqueClusters = _(clusters)
    .orderBy('id')
    .uniqWith((a, b) => a.streetViewId && a.streetViewId === b.streetViewId)
    .value();


  return (<>
    {sortedUniqueClusters.map((clusterData, idx) => {
      const {
        _id,
        ids,
        location: {Latitude: lat, Longitude: lng},
        isInPrivateFolder,
        isInSharedFolder,
        numPins,
        streetViewId,
        zoomToExpand,
      } = clusterData;

      // console.log(clusterData)

      const isCluster = !!numPins;

      const pin = clusterData;
      const colorPermission = isInSharedFolder
        ? '#FFC002'
        : isInPrivateFolder ? '#FE8181'
          : '#5EC374';

      const label = isCluster ? `Cluster of ${numPins}` : pin.label;

      return (
        <InAppMapPin
          color={isCluster ? '#33bbff' : colorPermission}
          label={label}
          iconUrl={`${window.location.origin}${InAppWebPinPano}`}
          // isHovering={isHovering}
          key={_id || ids}
          latitude={pin.location.Latitude}
          longitude={pin.location.Longitude}
          onClick={(e) => {
            if (isCluster) {

              if (isTabletop) {
                sendNavTo({
                  lat,
                  lon: lng,
                  zoom: zoomToExpand,
                });

                _.delay(() => { // bypass the debounce after mapnav to break up the cluster
                  refresh.flush();
                }, 300);
              } else {
                sendPlacesSearch({ids});
              }

              return;
            }

            sendPanoLoad({
              lat,
              lon: lng,
              panoId: streetViewId,
            });
          }}
          tooltip={isCluster ? isTabletop ? 'click to expand' : 'click to view in Places' : 'click to view 360'}
          // onHoverEnter={() => setIsPinHovering(true)}
          // onHoverExit={() => setIsPinHovering(false)}
          // tooltip="Tour Pin <br> Only visible to you"
        />
      );
    })}
  </>);
};

export default InAppPinsAndClusters;
