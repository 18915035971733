import {Flex, Heading, Stack, Text, useToast} from '@chakra-ui/react';
import React, {useRef} from 'react';
import {MdDirectionsRun, MdOutlinePanoramaPhotosphere} from 'react-icons/md';
import {useVuplex} from '../../../../hooks/useVuplex';
import ButtonMinor from '../../../../components/ButtonMinor';
import {sendNavTo} from '../../../../vuplex';
import {CloseIcon} from '@chakra-ui/icons';


const ToastContent = ({
  latitude,
  longitude,
  onClose,
}) => {
  const hasLocation = latitude !== 0 || longitude !== 0;

  return (
    <Flex
      alignItems="start"
      gap={3}
    >
      <Flex
        alignItems="flex-start"
        bg="#007AFF"
        borderRadius="2xl"
        color="white"
        gap={2}
        maxWidth="500px"
        p={2}
      >
        <Stack borderRight="solid 1px" borderColor="whiteAlpha.500" p={2}>
          <Heading
            color="white"
            noOfLines={1}
            size="md"
            textAlign="center"
          >
            Failed to load 360.
          </Heading>

          {hasLocation && (
            <>
              <Heading
                color="white"
                size="md"
                textAlign="center"
                w="full"
              >
                Teleport there anyway?
              </Heading>

              <Text color="whiteAlpha.600" textAlign="center">
                {latitude.toFixed(6)}, {longitude.toFixed(6)}
              </Text>

              <Flex justifyContent="center" gap={4} paddingTop={4}>
                <ButtonMinor
                  onClick={() => {
                    sendNavTo({
                      lat: latitude,
                      lon: longitude,
                      // zoom: map.getZoom() - zoomOffset,
                    });
                    onClose();
                  }}
                  tooltip="Teleport to this location"
                >
                  <MdDirectionsRun/>
                  &nbsp;
                  Go There
                </ButtonMinor>
              </Flex>
            </>
          )}
        </Stack>

        <ButtonMinor bg="none" borderRadius={0} onClick={onClose}>
          <CloseIcon color="white"/>
        </ButtonMinor>
      </Flex>
    </Flex>
  );
};



const PanoNoneForLocationToast = ({onClose}) => {
  const toast = useToast();
  const toastIdRef = useRef();
  const closeCurrentToast = () => toast.close(toastIdRef.current);

  useVuplex({
    panoFailedToLoad: ({latitude, longitude}) => {
      toast.closeAll();

      toastIdRef.current = toast({
        description: `The current 360 failed to load.`,
        duration: (latitude !== 0 || longitude !== 0) ? null : 3000,
        icon: <MdOutlinePanoramaPhotosphere color="#fff" size="1.5em"/>,
        onCloseComplete: onClose,
        position: 'bottom',
        render: () => <ToastContent latitude={latitude} longitude={longitude} onClose={closeCurrentToast}/>,
        status: 'error',
        title: 'No 360 found',
      });
    },
    noPanoForLocation: () => {
      toast.closeAll();

      toastIdRef.current = toast({
        description: `We could not find a 360 near this location.`,
        duration: 1500,
        icon: <MdOutlinePanoramaPhotosphere color="#fff" size="1.5em"/>,
        onCloseComplete: onClose,
        position: 'bottom',
        status: 'error',
        title: 'No 360 found',
      });
    },
  })

  return null;
};

export default PanoNoneForLocationToast;
